import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import moment from "moment";
import useSize from "../../../../Utils/useSize";
const backend = new Backend();
export default function MarketTime() {
  const windowSize = useSize();

  const [time, setTime] = useState("");

  useEffect(() => {
    loadTime();
  }, []);

  const loadTime = () => {
    var dt = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
    dt.format("dddd");
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
      date: dt.format("dddd").substring(0, 3).toUpperCase(),
    };

    backend.time(data).then((r) => {
      if (r.error == "False") {
        setTime(r.time);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div className="buysellmodal">
      <div className="closebuysell">ESC TO CLOSE</div>
      <div
        className="scrolldivu"
        style={{
          height: windowSize[1] - 200,
          overflowY: "scroll",
          textAlign: "center",
        }}
      >
        {time.length > 0 ? (
          time.map((item) => {
            return (
              <div
                style={{
                  height: 100,
                  display: "flex",
                  backgroundColor: Dark.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  borderRadius: 10,
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: Dark.text,
                    height: 20,
                    fontSize: 14,
                    backgroundColor: Dark.primary,
                    width: "30%",
                    textAlign: "center",
                    borderBottomRightRadius: 30,
                    borderBottomLeftRadius: 30,
                  }}
                >
                  {item.type == "fut"
                    ? "FUTURE"
                    : item.type == "mcx"
                    ? "MCX"
                    : item.type == "fo"
                    ? "OPTIONS"
                    : null}
                </div>
                <div
                  style={{
                    color: Dark.text,
                    height: 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 12,
                      color: Dark.buy,
                    }}
                  >
                    START-TIME : {item.start_time}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 12,
                    }}
                  >
                    Event :{" "}
                    {item.event == null || item.event == undefined
                      ? "No Event"
                      : item.event}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 12,
                      color: Dark.sell,
                    }}
                  >
                    END-TIME : {item.end_time}
                  </div>
                </div>

                <div
                  style={{
                    color: Dark.text,
                    height: 20,
                    fontSize: 14,
                    backgroundColor: item.status == 1 ? Dark.buy : Dark.sell,
                    width: "30%",
                    textAlign: "center",
                    borderTopRightRadius: 30,
                    borderTopLeftRadius: 30,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.status == 1 ? "Active" : "Deactive"}
                </div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Logs Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}

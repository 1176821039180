import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import moment from "moment";
import useSize from '../../../../Utils/useSize'

const backend = new Backend();
export default function Ledger() {
  const windowSize = useSize();
  const [ledger, setLedger] = useState("");

  useEffect(() => {
    loadLedger();
  }, []);

  const loadLedger = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.ledger(data).then((r) => {
      if (r.error == "False") {
        setLedger(r.ledger);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div className="buysellmodal">
      <div className="closebuysell">ESC TO CLOSE</div>
      <div
        style={{
          height: 30,
          display: "flex",
          backgroundColor: Dark.primary,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
          borderRadius: 10,
          paddingLeft: 10,
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          TYPE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          AMOUNT
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          MSG
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          DATE
        </div>
      </div>
      <div
        className="scrolldivu"
        style={{ height: windowSize[1] - 200, overflowY: "scroll",textAlign:"center" }}
      >
        {ledger.length > 0 ? (
          ledger.map((i) => {
            let amount =
              i.withdraw == 0
                ? i.deposit
                : i.deposit == 0
                ? i.withdraw
                : i.weekly_amt;
            return (
              <div
                style={{
                  height: 45,
                  display: "flex",
                  backgroundColor: Dark.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  borderRadius: 10,
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    color: i.withdraw == 0 ? Dark.buy : Dark.sell,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {i.withdraw == 0
                    ? "DEPOSIT"
                    : i.deposit == 0
                    ? "WITHDRAW"
                    : "WEEKLY CLOSING"}
                </div>
                <div
                  style={{
                    color: amount > 0 ? Dark.buy : Dark.sell,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {amount}
                </div>
                <div
                  style={{
                    color: amount > 0 ? Dark.buy : Dark.sell,
                    flex: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  {i.remarks}
                </div>

                <div
                  style={{
                    color: Dark.text,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {moment(i.date_created).format("DD-MM-YYYY HH:mm:ss")}
                </div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Ledger Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";

import useSize from '../../../../Utils/useSize'

const backend = new Backend();

export default function Wallet(props) {

  const windowSize = useSize()

  const [profile, setProfile] = useState(false);

  const [type, setType] = useState(0);

  const [amount, setAmount] = useState("");
  const [ptype, seyPType] = useState("");

  const [account, setAccount] = useState("");

  const [loading, setLoading] = useState(false);

  const [pay, setPay] = useState(false);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.profile(data).then((r) => {
      if (r.error == "False") {
        setProfile(r.profile);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ marginRight: 20, marginLeft: 20 }}>
      <div
        className="closebuysell"
        onClick={()=>windowSize[0] > "601" ? null : props.closemodal()}
        style={{
          color: windowSize[0] > "601" ? Dark.text : Dark.sell,
        }}
      >
        {windowSize[0] > "601" ? "ESC TO CLOSE" : "CLOSE"}{" "}
      </div>

      <div
        style={{
          height: 55,
          backgroundColor: Dark.secondary,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div
            onClick={() => {
              setType(0);
            }}
            style={{
              backgroundColor: type == 0 ? Dark.buy : Dark.background,
              width: 120,
              borderRadius: 10,
              height: 40,
              borderRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderWidth: 2,
              borderColor: Dark.buy,
            }}
          >
            DEPOSIT
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div
            onClick={() => {
              setType(1);
            }}
            style={{
              backgroundColor: type == 1 ? Dark.sell : Dark.background,
              width: 120,
              borderRadius: 10,
              height: 40,
              borderRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderWidth: 2,
              borderColor: Dark.sell,
            }}
          >
            WITHDRAW
          </div>
        </div>
      </div>

      <div
        style={{
          height: 350,
          backgroundColor: Dark.secondary,
          borderRadius: 10,
          display: "flex",
          flexDirection:"column",
          alignItems:"center"
        }}
      >
        <div
          style={{
            color: Dark.text,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Dark.primary,
            paddingLeft: 15,
            paddingRight: 15,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          CURRENT BALANCE - {profile.deposit_current}
        </div>
        <div
          style={{
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: 50, color: Dark.text }}>AMOUNT</div>
          <div>
            <InputText
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              type="number"
              placeholder="Enter Amount"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { AiOutlineHolder } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Dark, Light } from "../../../../Theme/Theme";

import "./Menu.css";

export default function Menu() {
  const theme = useSelector((state) => state.theme);
  return (
    <div className="containermenu">
      <div className="listmenufirst">
        <AiOutlineHolder
          style={{
            fontSize: 25,
            color: theme == 0 ? Dark.text : Light.text,
          }}
        />
      </div>
      <div
        className="listmenu"
        style={{
          flex: 2,
        }}
      >
        <div>SYMBOL</div>
      </div>
      <div className="listmenu">BID</div>
      <div className="listmenu">ASK</div>
      <div className="listmenu">LTP</div>
      <div className="listmenu">CHANGE</div>

      <div className="listmenu">HIGH</div>
      <div className="listmenu">LOW</div>
      <div className="listmenu">OPEN</div>
      <div className="listmenu">CLOSE</div>

      <div className="listmenumobile"></div>
      <div className="listmenumobile">BID</div>
      <div className="listmenumobile">ASK</div>
      <div className="listmenudelete"></div>
    </div>
  );
}

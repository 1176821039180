import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import SocketContext from "../../../../SocketContext/SocketContext";
import { toast } from "react-toast";
import Backend from "../../../../Backend/Backend";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import useSound from "use-sound";
import Errors from "../../../../Sound/error.mp3";
import Buy from "../../../../Sound/buy.mp3";
import Sell from "../../../../Sound/sell.mp3";

const backend = new Backend();
export default function SqOff(props) {
  const symbol = props.symbol;
  const socket = useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);

  const [bidcolor, setbidcolor] = useState("");
  const [askcolor, setaskcolor] = useState("");
  const [ltpcolor, setltpcolor] = useState("");

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(0);
  const [qty_order, setQty_order] = useState("");
  const [price, setprice] = useState("");

  const [standing, setStadning] = useState([]);

  const [playSoundError] = useSound(Errors);
  const [playSoundBuy] = useSound(Buy);
  const [playSoundSell] = useSound(Sell);

  useEffect(() => {
    loadStanding();
    socket.emit("giverate", symbol.symbol_id);
    socket.on("trade" + symbol.symbol_id, trade);
    socket.on("bidask" + symbol.symbol_id, bidask);
    return () => {
      socket.off("trade" + symbol.symbol_id, trade);
      socket.off("bidask" + symbol.symbol_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (bid !== msg.Bid) {
        if (msg.Bid > bid) {
          setbidcolor(Dark.buy);
        } else {
          setbidcolor(Dark.sell);
        }
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > ask) {
          setaskcolor(Dark.buy);
        } else {
          setaskcolor(Dark.sell);
        }
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        if (msg.LTP > ltp) {
          setltpcolor(Dark.buy);
        } else {
          setltpcolor(Dark.sell);
        }
        setltp(msg.LTP);
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
    }
  };
  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (msg.Bid > bid) {
        setbidcolor(Dark.buy);
      } else {
        setbidcolor(Dark.sell);
      }
    }
    if (ask !== msg.Ask) {
      if (msg.Ask > ask) {
        setaskcolor(Dark.buy);
      } else {
        setaskcolor(Dark.sell);
      }
      setask(msg.Ask);
    }
  };

  const loadStanding = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
      symbol_id: symbol.symbol_id,
    };

    backend.symbol_standing(data).then((r) => {
      if (r.error == "False") {
        setStadning(r.standing);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Buy_Sell = (a) => {
    if (qty_order < 0) {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        username: localStorage.getItem("username"),
        qty_order: qty_order,
        symbol_id: symbol.symbol_id,
        symbol: symbol.symbol,
        symbol_type: symbol.type,
        type: a,
      };

      backend.trade(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          setQty_order("");
          setprice("");
          setType(0);
          loadStanding();
          props.load()
          if (a == 0) {
            buy();
          } else {
            sell();
          }
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const Limit = (a) => {
    if (qty_order < 0) {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else if (price == "") {
      toast.error("Invalid Order Price !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        username: localStorage.getItem("username"),
        qty_order: qty_order,
        symbol_id: symbol.symbol_id,
        symbol: symbol.symbol,
        symbol_type: symbol.type,
        type: a,
        price: price,
      };

      backend.limit(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          if (a == 0) {
            buy();
          } else {
            sell();
          }
          setType(0);
          setQty_order("");
          setprice("");
          loadStanding();
          props.load()
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const StopLoss = (a) => {
    if (qty_order < 0) {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else if (price == "") {
      toast.error("Invalid Order Price !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        username: localStorage.getItem("username"),
        qty_order: qty_order,
        symbol_id: symbol.symbol_id,
        symbol: symbol.symbol,
        symbol_type: symbol.type,
        type: a,
        price: price,
      };

      backend.stoploss(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          if (a == 0) {
            buy();
          } else {
            sell();
          }
          setType(0);
          setQty_order("");
          setprice("");
          loadStanding();
          props.load()
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const buy = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundBuy();
    }
  };
  const sell = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundSell();
    }
  };
  const error = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundError();
    }
  };

  return (
    <div style={{ marginRight: 20, marginLeft: 20 }}>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        ESC TO CLOSE
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 120,
          backgroundColor: Dark.secondary,
          borderRadius: 10,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: 80,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              paddingLeft: 20,
              height: "100%",
              paddingTop: 5,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingBottom: 7,
              }}
            >
              <div style={{ fontSize: 12, paddingBottom: 5, color: Dark.text }}>
                BID
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: bidcolor == "" ? Dark.text : bidcolor,
                }}
              >
                {bid}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12, paddingBottom: 5, color: Dark.text }}>
                ASK
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: askcolor == "" ? Dark.text : askcolor,
                }}
              >
                {ask}
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ paddingBottom: 5, fontSize: 16, color: Dark.text }}>
              {symbol.symbol}
            </div>
            <div style={{ paddingBottom: 5, fontSize: 12, color: Dark.text }}>
              {moment(symbol.expiry_date).format("DD-MMM")}
            </div>
            <div
              style={{
                paddingBottom: 5,
                fontSize: 16,
                color: ltpcolor == "" ? Dark.text : ltpcolor,
              }}
            >
              LTP : {ltp}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingRight: 20,
              height: "100%",
              paddingTop: 5,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingBottom: 7,
                textAlign: "center",
              }}
            >
              <div style={{ fontSize: 12, paddingBottom: 5, color: Dark.buy }}>
                HIGH
              </div>
              <div style={{ fontSize: 14, color: Dark.buy }}>{high2}</div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div style={{ fontSize: 12, paddingBottom: 5, color: Dark.sell }}>
                LOW
              </div>
              <div style={{ fontSize: 14, color: Dark.sell }}>{low2}</div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {standing.length > 0 ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: standing[0].buy_sell == 0 ? Dark.buy : Dark.sell,
                  fontSize: 10,
                  paddingBottom: 5,
                }}
              >
                STANDING
              </div>
              <div
                style={{
                  color: standing[0].buy_sell == 0 ? Dark.buy : Dark.sell,
                  fontSize: 13,
                }}
              >
                {standing[0].buy_sell == 0 ? "BUY" : "SELL"} :{" "}
                {symbol.type == "fut"
                  ? standing[0].qty
                  : standing[0].qty / symbol.lot_size}{" "}
                {symbol.type == "fut" ? null : "LOT"}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 45,
          margin: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setType(0)}
            style={{
              color: Dark.text,
              fontSize: 12,
              height: 40,
              width: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: type == 0 ? Dark.primary : Dark.background,
              borderRadius: 20,
              borderStyle: "solid",
              borderWidth: 2,
              borderColor: Dark.primary,
            }}
          >
            MARKET
          </div>
        </div>
        {symbol.type == "fo" ? null : (
          <Fragment>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => setType(1)}
                style={{
                  color: Dark.text,
                  fontSize: 12,
                  height: 40,
                  width: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: type == 1 ? Dark.primary : Dark.background,
                  borderRadius: 20,
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: Dark.primary,
                }}
              >
                LIMIT
              </div>
            </div>
            <div
              onClick={() => setType(2)}
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: Dark.text,
                  fontSize: 12,
                  height: 40,
                  width: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: type == 2 ? Dark.primary : Dark.background,
                  borderRadius: 20,
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: Dark.primary,
                }}
              >
                STOP-LOSS
              </div>
            </div>
          </Fragment>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 45,
          margin: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              color: Dark.text,
              textAlign: "center",
              fontSize: 12,
              flex: 1,
            }}
          >
            {symbol.type == "fut" ? "QUANTITY " : "LOT"}
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <div className={"loginInputDarkSearch"}>
              <InputText
                value={qty_order}
                onChange={(e) => {
                  setQty_order(e.target.value);
                }}
                style={{
                  fontSize: 12,
                }}
                placeholder={
                  symbol.type == "fut" ? "ENTER QUANTITY" : "ENTER LOT SIZE"
                }
              />
            </div>
          </div>
        </div>
      </div>

      {type == 0 ? null : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 45,
            margin: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <div
              style={{
                color: Dark.text,
                textAlign: "center",
                fontSize: 12,
                flex: 1,
              }}
            >
              {"PRICE"}
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              <div className={"loginInputDarkSearch"}>
                <InputText
                  value={price}
                  onChange={(e) => {
                    setprice(e.target.value);
                  }}
                  style={{
                    fontSize: 12,
                  }}
                  placeholder={"ENTER PRICE"}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          position: "absolute",
          bottom: 0,
          margin: 10,
          height: 45,
          backgroundColor: Dark.background,
          width: "97%",
          alignSelf: "center",
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.type == 0 ? (
          <div
            onClick={() =>
              loading
                ? null
                : type == 0
                ? Buy_Sell(0)
                : type == 1
                ? Limit(0)
                : type == 2
                ? StopLoss(0)
                : null
            }
            style={{
              height: 40,
              backgroundColor: Dark.buy,
              flex: 1,
              marginLeft: 20,
              marginRight: 20,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Dark.text,
              fontSize: 18,
            }}
          >
            BUY
          </div>
        ) : null}

        {props.type == 1 ? (
          <div
            onClick={() =>
              loading
                ? null
                : type == 0
                ? Buy_Sell(1)
                : type == 1
                ? Limit(1)
                : type == 2
                ? StopLoss(1)
                : null
            }
            style={{
              height: 40,
              backgroundColor: Dark.sell,
              flex: 1,
              marginLeft: 20,
              marginRight: 20,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Dark.text,
              fontSize: 18,
            }}
          >
            SELL
          </div>
        ) : null}
      </div>
    </div>
  );
}

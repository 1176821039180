const iState = {
  theme: 0, //0 dark, 1 light
  pfdata: [],
  total: 0,
  btotal: 0,
  show: 0,
};

const Reducer = (state = iState, action) => {
  switch (action.type) {
    case "SHOW":
      return {
        ...state,
        show: action.payload,
      };
    case "THEME":
      return {
        ...state,
        theme: action.payload,
      };
    case "CLEAR_PF":
      return {
        ...state,
        pfdata: [],
        total: 0,
        btotal: 0,
      };
    case "PF": {
      if (action.total > 0) {
        var newpfdata = state.pfdata;

        if (Array.isArray(newpfdata)) {
          var found = false;
          for (var i = 0; i < newpfdata.length; i++) {
            if (newpfdata[i].symbol_id == action.symbol_id) {
              found = i;
              continue;
            }
          }
          if (found === false) {
            newpfdata.push({
              symbol_id: action.symbol_id,
              pf: action.pf,
              btotal: action.btotal,
            });
            var total = 0;
            var btotalnew = 0;
            newpfdata.map((i) => {
              total = parseFloat(total) + parseFloat(i.pf);
              btotalnew = parseFloat(btotalnew) + parseFloat(i.btotal);
            });
            return {
              ...state,
              pfdata: newpfdata,
              total: total,
              btotal: btotalnew,
            };
          } else {
            newpfdata[found].pf = action.pf;
            newpfdata[found].btotal = action.btotal;
            var total = 0;
            var btotalnew = 0;
            newpfdata.map((i) => {
              total = parseFloat(total) + parseFloat(i.pf);
              btotalnew = parseFloat(btotalnew) + parseFloat(i.btotal);
            });
            return {
              ...state,
              pfdata: newpfdata,
              total: total,
              btotal: btotalnew,
            };
          }
        } else {
          newpfdata.push({
            symbol_id: action.symbol_id,
            pf: action.pf,
            btotal: btotalnew,
          });
          return {
            ...state,
            pfdata: newpfdata,
            total: action.pf,
            btotal: btotalnew,
          };
        }
      }
    }

    default:
      return state;
  }
};

export default Reducer;

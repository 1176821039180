import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { toast } from "react-toast";
import Backend from "../../../../Backend/Backend";
import useSize from "../../../../Utils/useSize";
import "./Rules.css";
const backend = new Backend();
export default function Rules(props) {
  const windowSize = useSize();
  const [rules, setRules] = useState("");

  useEffect(() => {
    LoadRules();
  }, []);

  const LoadRules = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };
    backend.rules(data).then((r) => {
      if (r.error == "False") {
        setRules(r.rules);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const agree = () => {
    localStorage.setItem("rules", "1");
    props.close();
  };

  onkeydown = (event) => {
    if (event.key == "Enter") {
      agree();
    }
  };

  return (
    <div className="container">
      <div className="rulestitile">SERVER RULES & TURMS</div>

      <div
        className="scrolldivu"
        style={{ height: windowSize[1] - 210, overflowY: "scroll" }}
      >
        {rules.length > 0
          ? rules.map((i, t) => {
              return (
                <div className="rulescard">
                  <div className="rulestitle">{t + 1}.</div>
                  <div className="rulesmsg">{i.msg}.</div>
                </div>
              );
            })
          : null}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 10,
        }}
      >
        <div
          onClick={agree}
          style={{
            height: 40,
            width: 200,
            backgroundColor: Dark.primary,
            borderRadius: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          I AGREE
        </div>
      </div>
    </div>
  );
}

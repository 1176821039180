import React, { useContext, useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import SocketContext from "../../../../SocketContext/SocketContext";
import moment from "moment";
import "../Pending.css";
import { Dialog } from "primereact/dialog";
import BuySellCard from "../BuySellCard/BuySellCard";
import { useSelector } from "react-redux";

export default function PendingCard(props) {
  const item = props.item;

  const theme = useSelector((state) => state.theme);

  const socket = useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [ltp, setltp] = useState(0);

  const [modal, setModal] = useState(0);

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);
    socket.on("trade" + item.symbol_id, trade);
    socket.on("bidask" + item.symbol_id, bidask);
    return () => {
      socket.off("trade" + item.symbol_id, trade);
      socket.off("bidask" + item.symbol_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
    }
  };
  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
    }
  };

  return (
    <div
      onClick={() => (item.status == 0 ? setModal(true) : null)}
      style={{
        height: 50,
        display: "flex",
        backgroundColor: theme == 0 ? Dark.background : Light.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: theme == 0 ? Dark.primary : Light.primary,
        textAlign: "center",
      }}
    >
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
          flexDirection: "column",
        }}
      >
        <div>{item.symbol}</div>
        <div
          style={{
            fontSize: 8,
            paddingTop: 5,
            color: theme == 0 ? Dark.sell : Light.sell,
          }}
        >
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          color: Dark.buy,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {high2}
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {low2}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.qty}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.price}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {ltp}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.high_mid == 0 ? "HIGH-LOW" : "MID"}
      </div>
      <div
        style={{
          color: item.limit_sl == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.limit_sl == 0 ? "Limit" : "SL"}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.time_buy == null ? "Waiting" : item.time_buy}
      </div>
      <div
        style={{
          color: item.status == 2 ? Dark.sell : Dark.buy,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.status == 0 ? "Waiting" : item.msg}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 10,
        }}
      >
        {moment(item.date_created).format("DD-MM-YYYY HH:mm:ss")}
      </div>

      <Dialog
        showHeader={false}
        visible={modal}
        style={{ width: "40vw", height: "70vh" }}
        onHide={() => setModal(false)}
      >
        <BuySellCard
          close={() => {
            setModal(false);
            props.load();
          }}
          symbol={item}
          type={item.buy_sell}
          limit_sl={item.limit_sl == 0 ? 1 : 2}
        />
      </Dialog>
    </div>
  );
}

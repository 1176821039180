import React, { useEffect, useState } from "react";
import Marquees from "react-fast-marquee";
import { Dark, Light } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import Rules from "../Rules/Rules";
import { Dialog } from "primereact/dialog";
import useSize from "../../../../Utils/useSize";

import "./Marquee.css";

const backend = new Backend();

export default function Marquee() {

  const windowSize = useSize();
  const [marquees, setMarquee] = useState("");
  const [rules, setRules] = useState(false);

  useEffect(() => {
    load_marquee();
  }, []);

  const load_marquee = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
    };

    backend.load_marquee(data).then((r) => {
      if (r.error == "False") {
        setMarquee(r.marquee.text);
        if (localStorage.getItem("rules") == 1) {
        } else {
          setRules(true);
        }
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div className="containermarquee">
      <Marquees
        className="marqueetext"
      >
        {marquees == undefined || marquees == null || marquees == ""
          ? "NO HEADLINE FOUND"
          : marquees}
      </Marquees>

      <Dialog
        showHeader={false}
        visible={rules}
        className="marquee"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setRules(false)}
      >
        <Rules close={() => setRules(false)} />
      </Dialog>
    </div>
  );
}

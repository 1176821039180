import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { createStore } from "redux";
import Reducer from "./Components/Redux/Reducer";
import { Provider } from "react-redux";
const options = {
  position: "top right",
  timeout: 5000,
  offset: "30px",
  transition: "scale",
};
const root = ReactDOM.createRoot(document.getElementById("root"));
const store = createStore(Reducer);
root.render(
  <>
    <AlertProvider
      template={AlertTemplate}
      containerStyle={{
        background:"green"
      }}
      {...options}
    
    >
      <Provider store={store}>
        <App />
      </Provider>
    </AlertProvider>
  </>
);

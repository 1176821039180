import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SocketContect from "./Components/SocketContext/SocketContext";
import { Dark, Light } from "./Components/Theme/Theme";
import { ToastContainer } from "react-toast";
import io from "socket.io-client";
import { useSelector } from "react-redux";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./App.css";

import NiftyBankNifty from "./Components/Screen/MainStack/NiftyBankNifty/NiftyBankNifty";
import TopView from "./Components/Screen/MainStack/TopView/TopView";
import Login from "./Components/Screen/LoginStack/Login/Login";
import CPassword from "./Components/Screen/LoginStack/Password/CPassword";
import Watchlist from "./Components/Screen/MainStack/Watchlist/Watchlist";
import Standing from "./Components/Screen/MainStack/Position/Position";
import Pending from "./Components/Screen/MainStack/Pending/Pending";
import Order from "./Components/Screen/MainStack/Order/Order";

import useSize from "./Components/Utils/useSize";

const socket = io("https://sauda.io:40000");

let data = {
  token: localStorage.getItem("token"),
  id: localStorage.getItem("id"),
  server: localStorage.getItem("server"),
};

socket.emit("clientactive", data);

export default function App() {
  const windowSize = useSize();
  const theme = useSelector((state) => state.theme);

  return (
    <div
      style={{
        height: windowSize[1],
        backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
      }}
    >
      <ToastContainer  position="top-right" delay={3000} />
      {localStorage.getItem("AuthState") == "1" ? (
        <BrowserRouter>
          <SocketContect.Provider value={socket}>
            <NiftyBankNifty />
            <TopView />
            <div>
              <Routes>
                <Route path="/" element={<Watchlist />} />
                <Route path="*" element={<Watchlist />} />
                <Route path="/Watchlist" element={<Watchlist />} />
                <Route path="/Standing" element={<Standing />} />
                <Route path="/Pending" element={<Pending />} />
                <Route path="/Trade" element={<Order />} />
              </Routes>
            </div>
          </SocketContect.Provider>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/CPassword" element={<CPassword />} />
          </Routes>
        </BrowserRouter>
      )}

    </div>
  );
}

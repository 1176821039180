import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { InputSwitch } from "primereact/inputswitch";
import "./Setting.css";
export default function Setting() {
  const [sound, setSound] = useState(false);

  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        ESC TO CLOSE
      </div>

      <div
        style={{
          height: 45,
          display: "flex",
          backgroundColor: Dark.secondary,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          borderRadius: 10,
          paddingLeft: 10,
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            fontSize: 12,
            paddingLeft: 20,
          }}
        >
          SOUND
        </div>
        <div
          style={{
            color: Dark.sell,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
            textAlign: "center",
            paddingRight: 20,
          }}
        >
          <InputSwitch checked={sound} onChange={(e) => setSound(e.value)} />
        </div>
      </div>
    </div>
  );
}

const api_link = "https://sauda.io:50015";
const trade_link = "https://sauda.io:50003";
const pending_link = "https://sauda.io:50004";
// const api_link = "http://192.168.1.102:50015";
// const trade_link = "http://192.168.1.102:50003";
// const pending_link = "http://192.168.1.102:50004";
const EntryKeyClient = "77253fb276dc6683720cac012f140aa7";
const EntryKeyTrade = "7e0111cfd1f693e78f8d72c64e32221b";
const EntryKeyPending = "acbf9a6bd6b3610faa6ed9dbd344850c";

export default class Backend {
  server(data) {
    return fetch(api_link + "/find_server", {
      method: "POST",
      headers: {
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  demo(data) {
    return fetch(api_link + "/demo", {
      method: "POST",
      headers: {
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  login(data) {
    return fetch(api_link + "/login", {
      method: "POST",
      headers: {
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  cpassword(data) {
    return fetch(api_link + "/cpassword", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  activestate(data) {
    return fetch(api_link + "/activestate", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_imp(data) {
    return fetch(api_link + "/load_imp", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_index(data) {
    return fetch(api_link + "/load_index", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  hidden(data) {
    return fetch(api_link + "/hidden", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_marquee(data) {
    return fetch(api_link + "/load_marquee", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update(data) {
    return fetch(api_link + "/update", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  rules(data) {
    return fetch(api_link + "/rules", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  watchlist(data) {
    return fetch(api_link + "/watchlist", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol(data) {
    return fetch(api_link + "/symbol", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  find_symbol(data) {
    return fetch(api_link + "/find_symbol", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_symbol(data) {
    return fetch(api_link + "/add_symbol", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_symbol(data) {
    return fetch(api_link + "/delete_symbol", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_symbol(data) {
    return fetch(api_link + "/edit_symbol", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_standing(data) {
    return fetch(api_link + "/symbol_standing", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  standing(data) {
    return fetch(api_link + "/standing", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  pending(data) {
    return fetch(api_link + "/pending", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  cancel_pending(data) {
    return fetch(api_link + "/cancel_pending", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  order(data) {
    return fetch(api_link + "/order", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  profile(data) {
    return fetch(api_link + "/profile", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  ledger(data) {
    return fetch(api_link + "/ledger", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  rejection(data) {
    return fetch(api_link + "/rejection", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  chnage_password(data) {
    return fetch(api_link + "/chnage_password", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  history(data) {
    return fetch(api_link + "/history", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  qty(data) {
    return fetch(api_link + "/qty", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  time(data) {
    return fetch(api_link + "/time", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  news(data) {
    return fetch(api_link + "/news", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyClient: EntryKeyClient,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  trade(data) {
    return fetch(trade_link + "/trade", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyTrade: EntryKeyTrade,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  limit(data) {
    return fetch(pending_link + "/limit", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyPending: EntryKeyPending,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  stoploss(data) {
    return fetch(pending_link + "/stoploss", {
      method: "POST",
      headers: {
        server: data.server,
        auth: data.token,
        id: data.id,
        EntryKeyPending: EntryKeyPending,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
}

import React, { useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import Filter from './Filter/Filter'
import moment from "moment";

export default function LeftCard(props) {
  const limit = props.limit;
  const total = props.total;
  const sl = props.sl;
  const theme = useSelector((state) => state.theme);

  const [filter, setFilter] = useState(false);

  const [filtertype, setFilterType] = useState("");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Dark.background,
        padding: 10,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "70px",
            width: "200px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
          }}
        >
          <div style={{ color: theme == 0 ? Dark.text : Light.text }}>
            LIMIT
          </div>
          <div
            style={{
              color: Dark.sell,
              marginTop: 7,
              fontSize: 22,
            }}
          >
            {parseFloat(limit).toFixed(0)}
          </div>
        </div>

        <div
          style={{
            height: "70px",
            width: "200px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginLeft: 15,
          }}
        >
          <div style={{ color: Dark.text }}>PENDING</div>
          <div
            style={{
              color: theme == 0 ? Dark.primary : Light.primary,
              marginTop: 7,
              fontSize: 22,
            }}
          >
            {parseFloat(total).toFixed(0)}
          </div>
        </div>

        <div
          style={{
            height: "70px",
            width: "200px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginLeft: 15,
          }}
        >
          <div style={{ color: Dark.text }}>STOP-LOSS</div>
          <div
            style={{
              color: Dark.sell,
              marginTop: 7,
              fontSize: 22,
            }}
          >
            {parseFloat(sl).toFixed(0)}
          </div>
        </div>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
         <div
          onClick={() => setFilter(true)}
          style={{
            height: "70px",
            width: "200px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
            textAlign: "center",
            textTransform:"uppercase"
          }}
        >
          <div style={{ color: Dark.text, fontSize: 14 }}>Filter</div>
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              fontSize: 16,
            }}
          >
            {filtertype == "" || filtertype == "CUSTOM" ? (
              <>
                <div style={{ marginBottom: 3, fontSize: 12 }}>
                  START : {moment(props.start).format("DD-MM-YYYY")}
                </div>
                <div style={{ fontSize: 12 }}>
                  END :{moment(props.end).format("DD-MM-YYYY")}
                </div>
              </>
            ) : (
              filtertype
            )}
          </div>
        </div>
      </div>

      <Dialog
        header={"Filter List"}
        visible={filter}
        showHeader={false}
        style={{ width: "40vw", height: "45vh" }}
        onHide={() => setFilter(false)}
      >
        <Filter
          start={props.start}
          end={props.end}
          filter={props.filter}
          updatefilter={(a) => {
            setFilterType(a);
            setFilter(false);
          }}
          close={() => setFilter(false)}
        />
      </Dialog>
    </div>
  );
}

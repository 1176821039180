import React, { useState } from "react";
import ReactDragListView from "react-drag-listview";
import WatchlistCard from "./WatchlistCard";
import { useSelector } from "react-redux";
import { Dark, Light } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import BuySellCard from "../BuySellCard/BuySellCard";

import useSize from "../../../../Utils/useSize";

const backend = new Backend();
export default function WatchlistAll(props) {
  const windowSize = useSize();

  const theme = useSelector((state) => state.theme);

  const [modal, setModal] = useState("");
  const [symbol, setSymbol] = useState("");

  const [type, setType] = useState(0);

  const [count, setCount] = useState(1);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const datas = [...props.watchlist];
      const item = datas.splice(fromIndex, 1)[0];
      datas.splice(toIndex, 0, item);
      datas.map((i, t) => {
        i.sr_no = t + 1;
      });

      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        username: localStorage.getItem("username"),
        watchlist: datas,
        watchlist_id: props.selected,
      };


      backend.edit_symbol(data).then((r) => {
        if (r.error == "False") {
          props.load();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    },
    nodeSelector: "p",
  };

  onkeyup = (event) => {
    event.preventDefault();
    if (event.key == "ArrowUp") {
      setCount(count == 1 ? 1 : count - 1);
    }

    if (event.key == "ArrowDown") {
      setCount(props.watchlist.length == count ? count : count + 1);
    }

    if (props.watchlist.length > 0) {
      if (event.ctrlKey == true) {
        if (event.key == "+" || event.key == "=") {
          setSymbol(props.watchlist[count - 1]);
          setType(0);
          setModal(true);
        }

        if (event.key == "-" || event.key == "_") {
          setSymbol(props.watchlist[count - 1]);
          setType(1);
          setModal(true);
        }

        if (event.key == "Backspace") {
          delete_symbol(props.watchlist[count - 1]);
        }
      }
    }
  };

  const delete_symbol = (a) => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
      watchlist_id: props.selected,
      symbol_id: a.id,
    };

    backend.delete_symbol(data).then((r) => {
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const update = (a) => {
    setCount(a);
  };

  const open = (a) => {
    setSymbol(props.watchlist[count - 1]);
    setType(a);
    setModal(true);
  };

  return (
    <div key={ windowSize[0] > "601" ? modal : null}>
      <div
        className="scrolldivu"
        style={{
          height: windowSize[1] - 240,
          backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
          overflowY: "scroll",
          paddingLeft: "10px",
          paddingRight: "10px",
          padding: 10,
        }}
      >
        <div>
          {props.watchlist.length > 0 ? (
            <ReactDragListView {...dragProps}>
              {props.watchlist.map((i, t) => {
                return (
                  <p key={i.id} style={{ borderRadius: 10 }}>
                    <WatchlistCard
                      count={count}
                      key={i.id}
                      item={i}
                      index={t + 1}
                      load={props.load}
                      open={open}
                      update={update}
                    />
                  </p>
                );
              })}
            </ReactDragListView>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontSize: 22,
                  marginBottom: 5,
                  color: theme == 0 ? Dark.text : Light.text,
                }}
              >
                Nothing here
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: theme == 0 ? Dark.text : Light.text,
                }}
              >
                Use the search bar to add instruments.
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        showHeader={false}
        visible={modal}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setModal(false)}
      >
        <BuySellCard symbol={symbol} type={type} closemodal={() => setModal(false)} />
      </Dialog>
    </div>
  );
}

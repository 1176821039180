import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../Theme/Theme";
import TopCard from "./TopCard/TopCard";
import Menu from "./Menu";
import PositionCard from "./PositionCard/PositionCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import SqOff from "./SqOff/SqOff";
import useSize from "../../../Utils/useSize";
const backend = new Backend();

export default function Position() {

  const windowSize = useSize();

  const theme = useSelector((state) => state.theme);

  const [loading, setLoading] = useState(false);
  const [standing, setStanding] = useState([]);
  const [booked, setBooked] = useState(0);

  const [modal, setModal] = useState(false);

  const [symbol, setSymbol] = useState("");

  const [type, setType] = useState(0);

  useEffect(() => {
    loadStanding();
  }, []);

  const loadStanding = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setStanding(r.standing);
        setBooked(r.booked);
      } else {
        toast(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
      }
    });
  };

  const open = (a, b) => {
    setSymbol(a);
    setType(b);
    setModal(true);
  };

  return (
    <div
      style={{
        height: windowSize[1] - 100,
        backgroundColor: theme == 0 ? Dark.background : Light.background,
      }}
    >
      <TopCard booked={booked} />
      <Menu />
      <div style={{ overflowY: "scroll", height: "68vh" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Loading
              type="spin"
              height={30}
              width={30}
              color={theme == 0 ? Dark.text : Light.text}
            />
          </div>
        ) : standing.length > 0 ? (
          standing.map((i, t) => {
            return (
              <PositionCard
                item={i}
                total={standing.length}
                key={i.id}
                index={t + 1}
                open={open}
                load={loadStanding}
              />
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textTransform: "uppercase",
              height: "100%",
            }}
          >
            <div style={{ fontSize: 18, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text, fontSize: 14 }}>
              No Active Portfolio Found.
            </div>
          </div>
        )}
      </div>

      <Dialog
        showHeader={false}
        visible={modal}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setModal(false)}
      >
        <SqOff symbol={symbol} type={type} load={loadStanding} />
      </Dialog>
    </div>
  );
}

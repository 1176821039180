import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import SocketContext from "../../../../SocketContext/SocketContext";
import { toast } from "react-toast";
import Backend from "../../../../Backend/Backend";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import useSound from "use-sound";
import Errors from "../../../../Sound/error.mp3";
import Buy from "../../../../Sound/buy.mp3";
import Sell from "../../../../Sound/sell.mp3";
import useSize from "../../../../Utils/useSize";

import "./BuySell.css";

const backend = new Backend();

export default function BuySellCard(props) {
  const windowSize = useSize();
  const symbol = props.symbol;

  const socket = useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);

  const [bidcolor, setbidcolor] = useState("");
  const [askcolor, setaskcolor] = useState("");
  const [ltpcolor, setltpcolor] = useState("");

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(0);
  const [qty_order, setQty_order] = useState("");
  const [price, setprice] = useState("");

  const [standing, setStadning] = useState([]);

  const [symboldata, setSymbol] = useState("");

  const [playSoundError] = useSound(Errors);
  const [playSoundBuy] = useSound(Buy);
  const [playSoundSell] = useSound(Sell);

  useEffect(() => {
    symbols();
    loadStanding();
    // KeyCheck()
    socket.emit("giverate", symbol.symbol_id);
    socket.on("trade" + symbol.symbol_id, trade);
    socket.on("bidask" + symbol.symbol_id, bidask);
    return () => {
      socket.off("trade" + symbol.symbol_id, trade);
      socket.off("bidask" + symbol.symbol_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (bid !== msg.Bid) {
        if (msg.Bid > bid) {
          setbidcolor(Dark.buy);
        } else {
          setbidcolor(Dark.sell);
        }
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > ask) {
          setaskcolor(Dark.buy);
        } else {
          setaskcolor(Dark.sell);
        }
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        if (msg.LTP > ltp) {
          setltpcolor(Dark.buy);
        } else {
          setltpcolor(Dark.sell);
        }
        setltp(msg.LTP);
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
    }
  };
  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (msg.Bid > bid) {
        setbidcolor(Dark.buy);
      } else {
        setbidcolor(Dark.sell);
      }
    }
    if (ask !== msg.Ask) {
      if (msg.Ask > ask) {
        setaskcolor(Dark.buy);
      } else {
        setaskcolor(Dark.sell);
      }
      setask(msg.Ask);
    }
  };

  const symbols = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      type: symbol.type,
      symbol_id: symbol.symbol_id,
    };
    backend.symbol(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setSymbol(r.symbol);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadStanding = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      symbol_id: symbol.symbol_id,
    };

    backend.symbol_standing(data).then((r) => {
      if (r.error == "False") {
        setStadning(r.standing);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Buy_Sell = (a) => {
    if (qty_order < 0 || qty_order == "") {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        qty_order: qty_order,
        symbol_id: symbol.symbol_id,
        symbol: symbol.symbol_display,
        symbol_type: symbol.type,
        type: a,
      };

      backend.trade(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          setQty_order("");
          setprice("");
          setType(0);
          loadStanding();
          if (a == 0) {
            buy();
          } else {
            sell();
          }
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const Limit = (a) => {
    if (qty_order < 0) {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else if (price == "") {
      toast.error("Invalid Order Price !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        qty_order: qty_order,
        symbol_id: symbol.symbol_id,
        symbol: symbol.symbol_display,
        symbol_type: symbol.type,
        type: a,
        price: price,
      };

      backend.limit(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          if (a == 0) {
            buy();
          } else {
            sell();
          }
          setType(0);
          setQty_order("");
          setprice("");
          loadStanding();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const StopLoss = (a) => {
    if (qty_order < 0 || qty_order == "") {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else if (price == "") {
      toast.error("Invalid Order Price !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        qty_order: qty_order,
        symbol_id: symbol.symbol_id,
        symbol: symbol.symbol_display,
        symbol_type: symbol.type,
        type: a,
        price: price,
      };

      backend.stoploss(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          if (a == 0) {
            buy();
          } else {
            sell();
          }
          setType(0);
          setQty_order("");
          setprice("");
          loadStanding();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const buy = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundBuy();
    }
  };
  const sell = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundSell();
    }
  };
  const error = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundError();
    }
  };

  onkeyup = (event) => {
    event.preventDefault();
    if (event.key == "Enter") {
      event.preventDefault();
      if (type == 0) {
        if (props.type == 0) {
          Buy_Sell(0);
        } else if (props.type == 1) {
          Buy_Sell(1);
        } else {
          toast.error("Invalid Data !", {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      } else if (type == 1) {
        if (props.type == 0) {
          Limit(0);
        } else if (props.type == 1) {
          Limit(1);
        } else {
          toast.error("Invalid Data !", {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      } else if (type == 2) {
        if (props.type == 0) {
          StopLoss(0);
        } else if (props.type == 1) {
          StopLoss(1);
        } else {
          toast.error("Invalid Data !", {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      } else {
        toast.error("Invalid Data !", {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    }
  };

  return (
    <div className="buysellmodal">
      <div
        className="closebuysell"
        onClick={()=>windowSize[0] > "601" ? null : props.closemodal()}
        style={{
          color: windowSize[0] > "601" ? Dark.text : Dark.sell,
        }}
      >
        {windowSize[0] > "601" ? "ESC TO CLOSE" : "CLOSE"}{" "}
      </div>
      <div className="buysellheader">
        <div className="headerbidask">
          <div
            className="bidaskconatiner"
            style={{
              paddingLeft: 20,
            }}
          >
            <div className="buysellbidask">
              <div className="buybidasktext">BID</div>
              <div
                style={{
                  fontSize: 14,
                  color: bidcolor == "" ? Dark.text : bidcolor,
                }}
              >
                {bid}
              </div>
            </div>
            <div className="buysellbidask">
              <div className="buybidasktext">ASK</div>
              <div
                style={{
                  fontSize: 14,
                  color: askcolor == "" ? Dark.text : askcolor,
                }}
              >
                {ask}
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ paddingBottom: 5, fontSize: 16, color: Dark.text }}>
              {symbol.symbol_display}
            </div>
            <div style={{ paddingBottom: 5, fontSize: 12, color: Dark.text }}>
              {moment(symbol.expiry_date).format("DD-MMM")}
            </div>
            <div
              style={{
                paddingBottom: 5,
                fontSize: 16,
                color: ltpcolor == "" ? Dark.text : ltpcolor,
              }}
            >
              LTP : {ltp}
            </div>
          </div>

          <div
            className="bidaskconatiner"
            style={{
              paddingRight: 20,
            }}
          >
            <div className="buysellbidask">
              <div className="buybidasktext">HIGH</div>
              <div
                style={{
                  fontSize: 14,
                  color: Dark.buy,
                }}
              >
                {high2}
              </div>
            </div>
            <div className="buysellbidask">
              <div className="buybidasktext">LOW</div>
              <div
                style={{
                  fontSize: 14,
                  color: Dark.sell,
                }}
              >
                {low2}
              </div>
            </div>
          </div>
        </div>
        <div className="qtycontainer">
          {symbol.type == "fut" ? (
            <div className="minqtycontainer">
              <div className="minqtytext">Min/Qty</div>
              <div style={{ color: Dark.text, fontSize: 14 }}>
                {symboldata.min_qty}
              </div>
            </div>
          ) : (
            <div className="minqtycontainer">
              <div className="minqtytext">LOT SIZE</div>
              <div style={{ color: Dark.text, fontSize: 14 }}>
                {symboldata.lot_size}
              </div>
            </div>
          )}

          <div className="minqtycontainer">
            <div className="minqtytext">
              {symbol.type == "fut" ? "Per Order Max" : "Per Order Max Lot"}
            </div>
            <div style={{ color: Dark.text, fontSize: 14 }}>
              {symboldata == "" || symboldata == null
                ? 0
                : parseFloat(
                    symbol.type == "fut"
                      ? symboldata.qty_per_order
                      : symboldata.qty_per_order / symboldata.lot_size
                  ).toFixed(0)}
            </div>
          </div>

          <div className="minqtycontainer">
            <div className="minqtytext">
              {symbol.type == "fut" ? "Total Order Max" : "Total Order Max Lot"}
            </div>
            <div style={{ color: Dark.text, fontSize: 14 }}>
              {symboldata == "" || symboldata == null
                ? 0
                : parseFloat(
                    symbol.type == "fut"
                      ? symboldata.total_quantity
                      : symboldata.total_quantity / symboldata.lot_size
                  ).toFixed(0)}
            </div>
          </div>

          {standing.length > 0 ? (
            <div className="minqtycontainer">
              <div
                className="minqtytext"
                style={{
                  color: standing[0].buy_sell == 0 ? Dark.buy : Dark.sell,
                }}
              >
                STANDING
              </div>
              <div
                style={{
                  color: standing[0].buy_sell == 0 ? Dark.buy : Dark.sell,
                }}
                className="minqtytext"
              >
                {standing[0].buy_sell == 0 ? "BUY" : "SELL"} :{" "}
                {symbol.type == "fut"
                  ? standing[0].qty
                  : standing[0].qty / symboldata.lot_size}{" "}
                {symbol.type == "fut" ? null : "LOT"}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="buyselltype">
        <div className="typeitem">
          <div
            onClick={() => setType(0)}
            className="typebutton"
            style={{
              backgroundColor: type == 0 ? Dark.primary : Dark.background,
            }}
          >
            MARKET
          </div>
        </div>
        <div className="typeitem">
          <div
            onClick={() => setType(1)}
            className="typebutton"
            style={{
              backgroundColor: type == 1 ? Dark.primary : Dark.background,
            }}
          >
            LIMIT
          </div>
        </div>
        <div className="typeitem">
          <div
            className="typebutton"
            onClick={() => setType(2)}
            style={{
              backgroundColor: type == 2 ? Dark.primary : Dark.background,
            }}
          >
            STOP-LOSS
          </div>
        </div>
      </div>

      <div className="buyselltype">
        <div className="lotpricetext">
          {symbol.type == "fut" ? "QUANTITY " : "LOT"}
        </div>
        <div className="buyselltype">
          <div className={"loginInputDarkSearch"}>
            <InputText
              className="buysellinput"
              value={qty_order}
              onChange={(e) => {
                setQty_order(e.target.value);
              }}
              type="number"
              placeholder={
                symbol.type == "fut" ? "ENTER QUANTITY" : "ENTER LOT SIZE"
              }
            />
          </div>
        </div>
      </div>

      {type == 0 ? null : (
        <div
          className="buyselltype"
          style={{
            marginTop: 10,
          }}
        >
          <div className="lotpricetext">{"PRICE"}</div>
          <div className="buyselltype">
            <div className={"loginInputDarkSearch"}>
              <InputText
                className="buysellinput"
                type="number"
                value={price}
                onChange={(e) => {
                  setprice(e.target.value);
                }}
                placeholder={"ENTER PRICE"}
              />
            </div>
          </div>
        </div>
      )}

      <div className="buysellbuttonmain">
        {props.type == 2 ? (
          <>
            <div
              onClick={() =>
                loading
                  ? null
                  : type == 0
                  ? Buy_Sell(0)
                  : type == 1
                  ? Limit(0)
                  : type == 2
                  ? StopLoss(0)
                  : null
              }
              style={{
                height: 40,
                backgroundColor: Dark.buy,
                flex: 1,
                marginLeft: 20,
                marginRight: 20,
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: Dark.text,
                fontSize: 18,
              }}
            >
              BUY
            </div>
            <div
              onClick={() =>
                loading
                  ? null
                  : type == 0
                  ? Buy_Sell(1)
                  : type == 1
                  ? Limit(1)
                  : type == 2
                  ? StopLoss(1)
                  : null
              }
              style={{
                height: 40,
                backgroundColor: Dark.sell,
                flex: 1,
                marginLeft: 20,
                marginRight: 20,
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: Dark.text,
                fontSize: 18,
              }}
            >
              SELL
            </div>
          </>
        ) : null}

        {props.type == 0 ? (
          <div
            onClick={() =>
              loading
                ? null
                : type == 0
                ? Buy_Sell(0)
                : type == 1
                ? Limit(0)
                : type == 2
                ? StopLoss(0)
                : null
            }
            style={{
              height: 40,
              backgroundColor: Dark.buy,
              flex: 1,
              marginLeft: 20,
              marginRight: 20,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Dark.text,
              fontSize: 18,
            }}
          >
            BUY
          </div>
        ) : null}

        {props.type == 1 ? (
          <div
            onClick={() =>
              loading
                ? null
                : type == 0
                ? Buy_Sell(1)
                : type == 1
                ? Limit(1)
                : type == 2
                ? StopLoss(1)
                : null
            }
            style={{
              height: 40,
              backgroundColor: Dark.sell,
              flex: 1,
              marginLeft: 20,
              marginRight: 20,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Dark.text,
              fontSize: 18,
            }}
          >
            SELL
          </div>
        ) : null}
      </div>
    </div>
  );
}

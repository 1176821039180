import React from "react";
import { Dark } from "../../../../Theme/Theme";
import { useSelector } from "react-redux";

export default function LeftCard(props) {
  const total = useSelector((state) => state.total);
  const btotal = useSelector((state) => state.btotal);
  let m2m = parseFloat(total) + parseFloat(btotal);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Dark.background,
        padding: 10,
      }}
    >
      <div
        style={{
          height: "70px",
          width: "200px",
          backgroundColor: Dark.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 10,
          marginLeft: 15,
        }}
      >
        <div style={{ color: Dark.text }}>M2M</div>
        <div
          style={{
            color: m2m > 0 ? Dark.buy : Dark.sell,
            marginTop: 7,
            fontSize: 22,
          }}
        >
          {parseFloat(m2m).toFixed(0)}
        </div>
      </div>

      <div
        style={{
          height: "70px",
          width: "200px",
          backgroundColor: Dark.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 10,
          marginLeft: 15,
        }}
      >
        <div style={{ color: Dark.text }}>BROKERAGE</div>
        <div
          style={{
            color: Dark.sell,
            marginTop: 7,
            fontSize: 22,
          }}
        >
          {parseFloat(btotal).toFixed(0)}
        </div>
      </div>

      <div
        style={{
          height: "70px",
          width: "200px",
          backgroundColor: Dark.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 10,
          marginLeft: 15,
        }}
      >
        <div style={{ color: Dark.text }}>Profit/Loss</div>
        <div
          style={{
            color: total > 0 ? Dark.buy : Dark.sell,
            marginTop: 7,
            fontSize: 22,
          }}
        >
          {parseFloat(total).toFixed(0)}
        </div>
      </div>

      <div
        style={{
          height: "70px",
          width: "200px",
          backgroundColor: Dark.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 10,
          marginLeft: 15,
        }}
      >
        <div style={{ color: Dark.text }}>NET P&L</div>
        <div
          style={{
            color: parseFloat(props.booked + total) > 0 ? Dark.buy : Dark.sell,
            marginTop: 7,
            fontSize: 22,
          }}
        >
          {parseFloat(props.booked + total).toFixed(0)}
        </div>
      </div>
    </div>
  );
}

import React, {  useRef, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
import { InputText } from "primereact/inputtext";
import "./Symbol.css";
import { useSelector } from "react-redux";
import SymbolCard from "./SymbolCard";
import { toast } from "react-toast";
import hotkeys from "hotkeys-js";

const backend = new Backend();

export default function Symbol(props) {
  const inputRef = useRef();
  const inputAdd = useRef();
  const theme = useSelector((state) => state.theme);

  const [search_text, setScript_Text] = useState("");
  const [searchsymbol, setSearchSymbol] = useState("");
  const [symbol, setSymbol] = useState([]);

  const [show, setShow] = useState(false);

  const [count, setCount] = useState(1);

  const find_script = (a) => {
    if (a.length > 0) {
      setSearchSymbol(a);
      let data = {
        server: localStorage.getItem("server"),
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        username: localStorage.getItem("username"),
        watchlist_id: props.selected,
        name: a,
      };

      backend.find_symbol(data).then((r) => {
        if (r.error == "False") {
          setSymbol(r.symbol);
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    } else {
      setSearchSymbol("");
      setShow(false);
    }
  };

  const add_symbol = (i) => {
    let data = {
      server: localStorage.getItem("server"),
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
      symbol: i,
      watchlist_id: props.selected,
    };

    backend.add_symbol(data).then((r) => {
      if (r.error == "False") {
        setShow(true);
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  hotkeys("esc,ctrl+s,ctrl+a", function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault();

    if (event.ctrlKey == true) {
      if (event.key == "S" || event.key == "s") {
        inputRef.current.focus();
      }

      if (event.key == "A" || event.key == "a") {
        inputAdd.current.focus();
        setShow(true);
      }
    }
  });

  const hanlekey = (a) => {
    if (a.key == "Escape") {
      setShow(false);
      setScript_Text("");
      props.search("");
      find_script("");
      inputRef.current.blur();
      inputAdd.current.blur();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 45,
      }}
    >
      <div className={"loginInputDarkSearch"}>
        <InputText
          ref={inputRef}
          value={search_text}
          // autoFocus={true}
          onChange={(e) => {
            props.search(e.target.value);
            setScript_Text(e.target.value);
          }}
          onKeyDown={hanlekey}
          placeholder="Search Symbol"
          autocomplete="new-password"
        />
      </div>

      <div className={"loginInputDarkSearch"}>
        <InputText
          ref={inputAdd}
          value={searchsymbol}
          // autoFocus={true}
          onKeyDown={hanlekey}
          onChange={(e) => {
            find_script(e.target.value);
          }}
          placeholder="Add Symbol"
        />

        {show || searchsymbol.length > 0 ? (
          <div
            className="scrolldivu"
            style={{
              position: "absolute",
              top: "200px",
              zIndex: 100,
              height: "65%",
              width: 300,
              right: 10,
              borderRadius: 10,
              overflowY: "scroll",
              backgroundColor: theme == 0 ? Dark.primary : Light.primary,
              color: theme == 0 ? Dark.text : Light.text,
              transition: "all .2s",
              padding: 10,
            }}
          >
            {symbol.length > 0 ? (
              symbol.map((i, t) => {
                return (
                  <SymbolCard
                    count={count}
                    item={i}
                    index={t + 1}
                    key={i.id}
                    selected={props.selected}
                    load={props.load}
                    add_symbol={add_symbol}
                  />
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    fontSize: 22,
                    marginBottom: 5,
                    color: theme == 0 ? Dark.text : Light.text,
                  }}
                >
                  Nothing here
                </div>
                <div
                  style={{
                    fontSize: 14,
                    color: theme == 0 ? Dark.text : Light.text,
                  }}
                >
                  This Name Symbol Is Not Found.
                </div>
              </div>
            )}
          </div>
        ) : null}

        {searchsymbol.length > 0 ? (
          <div
            onClick={() => find_script("")}
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 99,
            }}
          ></div>
        ) : null}
      </div>
    </div>
  );
}

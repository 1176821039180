import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dark, Light } from "../../../Theme/Theme";
import Menu from "./Menu/Menu";
import "./Watchlist.css";
import Symbol from "./Symbol/Symbol";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import Marquee from "./Marquee/Marquee";
import WatchlistAll from "./Watchlist/WatchlistAll";

import useSize from "../../../Utils/useSize";

const backend = new Backend();

export default function Watchlist() {
  const windowSize = useSize();

  const theme = useSelector((state) => state.theme);
  const [selected, setSelected] = useState(1);

  const [loading, setLoading] = useState(false);

  const [watchlist, setWatchlist] = useState([]);

  const [watchlist_search, setWatchlist_Search] = useState([]);

  useEffect(() => {
    LoadSymbol(null);
  }, []);

  const LoadSymbol = (a) => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      watchlist_id: a == null ? selected : a,
    };

    backend.watchlist(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setWatchlist(r.watchlist);
        setWatchlist_Search(r.watchlist);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const search = (a) => {
    if (a.length > 1) {
      var data = watchlist_search.filter((x) =>
        x.symbol_display.toLowerCase().includes(a.toLowerCase())
      );
      setWatchlist(data);
    } else {
      setWatchlist(watchlist_search);
    }
  };

  onkeydown = (event) => {
    if (event.ctrlKey == true && event.key == "1") {
      event.preventDefault();
      if (selected == 1) {
      } else {
        setSelected(1);
        LoadSymbol(1);
      }
    }
    if (event.ctrlKey == true && event.key == "2") {
      event.preventDefault();
      if (selected == 2) {
      } else {
        setSelected(2);
        LoadSymbol(2);
      }
    }
    if (event.ctrlKey == true && event.key == "3") {
      event.preventDefault();
      if (selected == 3) {
      } else {
        setSelected(3);
        LoadSymbol(3);
      }
    }
    if (event.ctrlKey == true && event.key == "4") {
      event.preventDefault();
      if (selected == 4) {
      } else {
        setSelected(4);
        LoadSymbol(4);
      }
    }
    if (event.ctrlKey == true && event.key == "5") {
      event.preventDefault();
      if (selected == 5) {
      } else {
        setSelected(5);
        LoadSymbol(5);
      }
    }
  };

  return (
    <div
      style={{
        height: windowSize[1] - 100,
        backgroundColor: theme == 0 ? Dark.background : Light.background,
        color: Dark.text,
        zIndex: 1,
      }}
    >
      <Marquee />

      <div className="watchlistcontainer">
        <div className="watchlistchildcontainer">
          <div
            className="watchlistcard"
            onClick={() => {
              setSelected(1);
              LoadSymbol(1);
            }}
            style={{
              backgroundColor:
                selected == 1
                  ? theme == 0
                    ? Dark.primary
                    : Light.primary
                  : theme == 0
                  ? Dark.background
                  : Light.background,
            }}
          >
            Watchlist
          </div>

          <div
            onClick={() => {
              setSelected(2);
              LoadSymbol(2);
            }}
            className="watchlistcard"
            style={{
              backgroundColor:
                selected == 2
                  ? theme == 0
                    ? Dark.primary
                    : Light.primary
                  : theme == 0
                  ? Dark.background
                  : Light.background,
            }}
          >
            <div className="watchlisttext">Watchlist&nbsp;</div> 2
          </div>

          <div
            onClick={() => {
              setSelected(3);
              LoadSymbol(3);
            }}
            className="watchlistcard"
            style={{
              backgroundColor:
                selected == 3
                  ? theme == 0
                    ? Dark.primary
                    : Light.primary
                  : theme == 0
                  ? Dark.background
                  : Light.background,
            }}
          >
           <div className="watchlisttext">Watchlist&nbsp;</div> 3
          </div>

          <div
            onClick={() => {
              setSelected(4);
              LoadSymbol(4);
            }}
            className="watchlistcard"
            style={{
              backgroundColor:
                selected == 4
                  ? theme == 0
                    ? Dark.primary
                    : Light.primary
                  : theme == 0
                  ? Dark.background
                  : Light.background,
            }}
          >
            <div className="watchlisttext">Watchlist&nbsp;</div> 4
          </div>

          <div
            onClick={() => {
              setSelected(5);
              LoadSymbol(5);
            }}
            className="watchlistcard"
            style={{
              backgroundColor:
                selected == 5
                  ? theme == 0
                    ? Dark.primary
                    : Light.primary
                  : theme == 0
                  ? Dark.background
                  : Light.background,
            }}
          >
            <div className="watchlisttext">Watchlist&nbsp;</div> 5
          </div>
        </div>
        <Symbol selected={selected} load={LoadSymbol} search={search} />
      </div>
      <Menu />

      <div
        key={watchlist}
        style={{
          height: windowSize[1] - 240,
          backgroundColor: theme == 0 ? Dark.background : Light.background,
        }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : watchlist.length > 0 ? (
          <WatchlistAll
            watchlist={watchlist}
            selected={selected}
            load={LoadSymbol}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 22,
                marginBottom: 5,
                color: theme == 0 ? Dark.text : Light.text,
              }}
            >
              Nothing here
            </div>
            <div
              style={{
                fontSize: 14,
                color: theme == 0 ? Dark.text : Light.text,
              }}
            >
              Use the search bar to add instruments.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

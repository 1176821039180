import React from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import moment from "moment";
import { useSelector } from "react-redux";

export default function OrderCard(props) {
  const theme = useSelector((state) => state.theme);

  const item = props.item;

  let m2m = parseFloat(item.profit_loss + item.brokerage_client_amt).toFixed(0);

  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: theme == 0 ? Dark.background : Light.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: theme == 0 ? Dark.primary : Light.primary,
        textAlign: "center",
      }}
    >
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          flexDirection: "column",
        }}
      >
        <div>{item.symbol}</div>
        <div
          style={{
            fontSize: 8,
            paddingTop: 5,
            color: theme == 0 ? Dark.sell : Light.sell,
          }}
        >
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {item.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {item.qty}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {item.rate}
      </div>
      <div
        style={{
          color:
            item.type == "NEW SAUDA" || item.type == "SAUDA ADDED"
              ? Dark.buy
              : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {item.type}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {item.new_deposit}
      </div>
      <div
        style={{
          color: m2m > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {item.type == "NEW SAUDA" || item.type == "SAUDA ADDED" ? 0 : m2m}
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {parseFloat(item.brokerage_client_amt).toFixed(2)}
      </div>
      <div
        style={{
          color: item.profit_loss > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {parseFloat(item.profit_loss).toFixed(2)}
      </div>
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {moment(item.date_created).format("DD-MM-YYYY HH:mm:ss")}
      </div>
    </div>
  );
}

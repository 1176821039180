import React, { useContext, useEffect, useState } from "react";
import { AiOutlineHolder } from "react-icons/ai";
import { Dark, Light } from "../../../../Theme/Theme";
import moment from "moment";
import SocketContext from "../../../../SocketContext/SocketContext";
import { useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import Backend from "../../../../Backend/Backend";
import "./WatclistCard.css";
import { toast } from "react-toast";
import useSize from "../../../../Utils/useSize";
const backend = new Backend();

export default function WatchlistCard(props) {
  const windowSize = useSize();

  const item = props.item;
  const theme = useSelector((state) => state.theme);
  const socket = useContext(SocketContext);
  const [loading, setLoading] = useState(0);

  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);
  const [time, setTime] = useState(new Date());

  const [bidcolor, setbidcolor] = useState("");
  const [askcolor, setaskcolor] = useState("");
  const [ltpcolor, setltpcolor] = useState("");

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);
    socket.on("trade" + item.symbol_id, trade);
    socket.on("bidask" + item.symbol_id, bidask);
    return () => {
      socket.off("trade" + item.symbol_id, trade);
      socket.off("bidask" + item.symbol_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      setTime(msg.Time);
      if (bid !== msg.Bid) {
        if (msg.Bid > 0) {
          if (msg.Bid > bid) {
            setbidcolor(Dark.buy);
          } else {
            setbidcolor(Dark.sell);
          }
          setbid(msg.Bid);
        }
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > 0) {
          if (msg.Ask > ask) {
            setaskcolor(Dark.buy);
          } else {
            setaskcolor(Dark.sell);
          }
          setask(msg.Ask);
        }
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        if (msg.LTP > 0) {
          if (msg.LTP > ltp) {
            setltpcolor(Dark.buy);
          } else {
            setltpcolor(Dark.sell);
          }
          setltp(msg.LTP);
        }
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
    }
  };
  const bidask = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      setTime(msg.Time);
      if (bid !== msg.Bid) {
        if (msg.Bid > 0) {
          if (msg.Bid > bid) {
            setbidcolor(Dark.buy);
          } else {
            setbidcolor(Dark.sell);
          }
          setbid(msg.Bid);
        }
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > 0) {
          if (msg.Ask > ask) {
            setaskcolor(Dark.buy);
          } else {
            setaskcolor(Dark.sell);
          }
          setask(msg.Ask);
        }
      }
    }
  };

  const delete_symbol = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
      watchlist_id: props.selected,
      symbol_id: item.id,
    };

    backend.delete_symbol(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      key={item.id}
      className="watchlistcarditem"
      style={{
        borderColor:
          props.count == props.index
            ? Dark.primary
            : theme == 0
            ? Dark.background
            : Light.background,
      }}
    >
      <a className="swip">
        <div>
          <AiOutlineHolder style={{ fontSize: 25 }} />
        </div>
      </a>

      <div
        onClick={() => {
          props.open(2);
        }}
        className="leftcardmonile"
      >
        <div className="time">
          {moment(time).format("DD-MM-YYYY HH:mm:ss")}{" "}
        </div>
        <div className="symbolname">
          {props.item.symbol_display}{" "}
          <div className="expiry">
            {moment(item.expiry_date).format("DD-MMM")}
          </div>{" "}
        </div>
        <div
          className="livetime"
          style={{
            color: chng > 0 ? Dark.buy : Dark.sell,
          }}
        >
          Chng
          {" " + parseFloat(chng).toFixed(2)}{" "}
          {"(" + parseFloat(percent).toFixed(2) + "%)"}
        </div>
      </div>

      <div className="rightcardmobile">
        <div
          onClick={() => {
            props.open(2);
          }}
          className="bidaskcontainer"
        >
          <div
            style={{
              color:
                bidcolor == ""
                  ? theme == 0
                    ? Dark.text
                    : Light.text
                  : bidcolor,
            }}
          >
            {bid}
          </div>
          <div className="highlow"> L : {low2} </div>
        </div>

        <div
          onClick={() => {
            props.open(2);
          }}
          className="bidaskcontainer"
        >
          <div
            style={{
              color:
                askcolor == ""
                  ? theme == 0
                    ? Dark.text
                    : Light.text
                  : askcolor,
            }}
          >
            {ask}{" "}
          </div>
          <div className="highlow">H : {high2} </div>
        </div>

        <div onClick={delete_symbol} className="deletebuttonmobile">
          <RiDeleteBin5Line color={Dark.sell} />
        </div>
      </div>

      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="websymbolname"
      >
        <div>{props.item.symbol_display}</div>
        <div className="webexpiry">
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>

      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="webbidask"
      >
        <a
          style={{
            color:
              bidcolor == ""
                ? theme == 0
                  ? Dark.text
                  : Light.background
                : bidcolor,
          }}
        >
          {bid}
        </a>
      </div>

      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="webbidask"
      >
        <div
          style={{
            color:
              askcolor == ""
                ? theme == 0
                  ? Dark.text
                  : Light.background
                : askcolor,
          }}
        >
          {" "}
          {ask}
        </div>
      </div>
      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="webbidask"
      >
        <div
          style={{
            color:
              ltpcolor == ""
                ? theme == 0
                  ? Dark.text
                  : Light.background
                : ltpcolor,
          }}
        >
          {" "}
          {ltp}
        </div>
      </div>
      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="webbidask"
        style={{
          color: chng > 0 ? Dark.buy : Dark.sell,
          fontSize: 12,
        }}
      >
        {parseFloat(chng).toFixed(2)}{" "}
        {"(" + parseFloat(percent).toFixed(2) + "%)"}
      </div>

      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="webbidask"
      >
        {high2}
      </div>
      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="webbidask"
      >
        {low2}
      </div>
      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="webbidask"
      >
        {open}
      </div>
      <div
        onClick={() => {
          props.update(props.index);
        }}
        className="webbidask"
      >
        {close}
      </div>
      {props.count == props.index ? (
        <div className="buysellcontainer">
          <div className="buysellbox">
            <div
              onClick={() => {
                props.open(0);
              }}
              className="buysellbutton"
              style={{
                backgroundColor: Dark.buy,
              }}
            >
              BUY
            </div>
          </div>
          <div className="buysellbox">
            <div
              onClick={() => {
                props.open(1);
              }}
              className="buysellbutton"
              style={{
                backgroundColor: Dark.sell,
              }}
            >
              SELL
            </div>
          </div>
          <div
            onClick={loading ? null : delete_symbol}
            className="deletebutton"
          >
            <RiDeleteBin5Line size={30} color={Dark.sell} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

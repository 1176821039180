import React, { useState } from "react";
import { Dark, Light } from "../../../Theme/Theme";
import Logo from "../../../Photos/Sauda.png";
import LogoSub from "../../../Photos/LogoSub.png";
import { InputText } from "primereact/inputtext";
import ReactLoading from "react-loading";
import { toast } from "react-toast";
import Backend from "../../../Backend/Backend";
import { useSelector } from "react-redux";
import "./CPassword.css";

const backend = new Backend();
export default function CPassword() {
  const theme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);

  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const onSubmit = () => {
    if (oldpassword == "") {
      toast.error("Invalid Server", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (newpassword == "") {
      toast.error("Invalid Username", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (cpassword != newpassword) {
      toast.error("Password are not same !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);

      let data = {
        server: localStorage.getItem("server"),
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        oldpassword: oldpassword,
        cpassword: cpassword,
      };

      backend.cpassword(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          localStorage.removeItem("server");
          window.location = "/";
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  onkeydown = (event) => {
    if (event.key == "Enter") {
      onSubmit();
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
      }}
    >
      <div className={theme == 0 ? "loginviewdark" : "loginviewlight"}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 20,
          }}
        >
          <img src={Logo} style={{ height: "140px", width: "140px" }} />
          <img src={LogoSub} style={{ height: "140px" }} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 10,
          }}
        >
          <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
            <div
              style={{
                color: theme == 0 ? Dark.text : Light.text,
                marginBottom: 7,
                textAlign: "center",
                fontSize: 18,
              }}
            >
              OLD PASSWORD
            </div>
            <InputText
              value={oldpassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              placeholder="Enter Old Password"
              autocomplete="new-password"
            />
          </div>

          <>
            <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  marginBottom: 7,
                  textAlign: "center",
                  fontSize: 18,
                }}
              >
                NEW PASSWORD
              </div>
              <InputText
                value={newpassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                placeholder="Enter New Password"
                autocomplete="new-password"
              />
            </div>
            <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  marginBottom: 7,
                  textAlign: "center",
                  fontSize: 18,
                }}
              >
                CONFIRN PASSWORD
              </div>
              <InputText
                value={cpassword}
                autoComplete="off"
                type="password"
                onChange={(e) => {
                  setCPassword(e.target.value);
                }}
                placeholder="Enter Confirm Password"
                autocomplete="new-password"
              />
            </div>
          </>

          <div
            onClick={loading ? null : onSubmit}
            className={theme == 0 ? "loginbuttondark" : "loginbuttonlight"}
          >
            {loading ? (
              <ReactLoading
                type={"spin"}
                color={Dark.text}
                height={19}
                width={19}
              />
            ) : (
              "SUBMIT"
            )}
          </div>
          <div
            style={{
              fontSize: 10,
              color: theme == 0 ? Dark.text : Light.text,
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            ONE TIME CHANGE PASSWORD
          </div>
        </div>
      </div>
    </div>
  );
}

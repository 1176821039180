import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../Theme/Theme";
import Logo from "../../../Photos/Sauda.png";
import LogoSub from "../../../Photos/LogoSub.png";
import { BiLogOut, BiUserCircle, BiSupport } from "react-icons/bi";
import { Dialog } from "primereact/dialog";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Wallet from "./Wallet/Wallet";
import Profile from "./Profile/Profile";
import Ledger from "./Ledger/Ledger";
import Logs from "./Logs/Logs";
import QtySetting from "./QtySetting/QtySetting";
import History from "./History/History";
import MarketTime from "./MarketTime/MarketTime";
import Setting from "./Setting/Setting";
import { confirmAlert } from "react-confirm-alert";
import { IoWallet } from "react-icons/io5";
import Backend from "../../../Backend/Backend";

import hotkeys from "hotkeys-js";

import "./Topview.css";
import { toast } from "react-toast";
import useSize from "../../../Utils/useSize";

const backend = new Backend();

export default function TopView() {
  const windowSize = useSize();
  const path = window.location.pathname;
  const navigation = useNavigate();

  const theme = useSelector((state) => state.theme);
  const [select, setSelect] = useState(false);
  const [checked, setChecked] = useState(1);

  const [profile, setProfile] = useState(false);
  const [ledger, setLedger] = useState(false);
  const [log, setLog] = useState(false);
  const [qtysetting, setQtySetting] = useState(false);
  const [history, setHistory] = useState(false);
  const [time, setTime] = useState(false);
  const [setting, setSetting] = useState(false);
  const [wallet, setWallet] = useState(false);

  const [profiledata, setProfileData] = useState("");

  const [masterintraday, setMasterIntraday] = useState(0);

  useEffect(() => {
    setChecked(theme == 0 ? false : true);
    loadProfile();
    loadHidden();
  }, []);

  const Logout = () => {
    confirmAlert({
      title: "Confirm to Logout",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("id");
            localStorage.removeItem("username");
            localStorage.removeItem("server");
            localStorage.removeItem("rules");
            localStorage.removeItem("AuthState");
            window.location = "/";
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const loadProfile = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.profile(data).then((r) => {
      if (r.error == "False") {
        setProfileData(r.profile);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadHidden = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };
    backend.hidden(data).then((r) => {
      if (r.error == "False") {
        setMasterIntraday(r.masterintraday);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  hotkeys("ctrl+w,ctrl+o,ctrl+t,ctrl+p", function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault();

    if (event.shiftKey == true) {
      if (event.key == "w" || event.key == "W") {
        navigation("/Watchlist");
      }

      if (event.key == "o" || event.key == "O") {
        navigation("/Standing");
      }

      if (event.key == "t" || event.key == "T") {
        navigation("/Trade");
      }

      if (event.key == "p" || event.key == "P") {
        navigation("/Pending");
      }
    }
  });

  let balance =
    profiledata == undefined
      ? 0
      : profiledata.deposit_current == null
      ? 0
      : profiledata.deposit_current;

  return (
    <div className="topcontainer">
      <Link to={"/"} className="topimagecontainer">
        <img src={Logo} style={{ height: "70px" }} />
        <img src={LogoSub} style={{ height: "20px" }} />
      </Link>
      <div className="linkcontainer">
        <div
          className={"linkdark"}
          onClick={() => {
            setSelect(!select);
            navigation("/Watchlist");
          }}
          style={{
            backgroundColor:
              path == "/" || path == "/Watchlist"
                ? theme == 0
                  ? Dark.primary
                  : Light.primary
                : theme == 0
                ? Dark.background
                : Light.background,
          }}
        >
          <div className="redline">W</div>
          <div>ATCHLIST</div>
        </div>

        <div
          className={"linkdark"}
          onClick={() => {
            setSelect(!select);
            navigation("/Standing");
          }}
          style={{
            backgroundColor:
              path == "/Standing"
                ? theme == 0
                  ? Dark.primary
                  : Light.primary
                : theme == 0
                ? Dark.background
                : Light.background,
          }}
        >
          <div>P</div>
          <div className="redline">O</div>
          <div>RTFOLIO</div>
        </div>

        <div
          className={"linkdark"}
          onClick={() => {
            setSelect(!select);
            navigation("/Pending");
          }}
          style={{
            backgroundColor:
              path == "/Pending"
                ? theme == 0
                  ? Dark.primary
                  : Light.primary
                : theme == 0
                ? Dark.background
                : Light.background,
          }}
        >
          <div className="redline">P</div>
          <div>ENDING</div>
        </div>

        <div
          className={"linkdark"}
          onClick={() => {
            setSelect(!select);
            navigation("/Trade");
          }}
          style={{
            backgroundColor:
              path == "/Trade"
                ? theme == 0
                  ? Dark.primary
                  : Light.primary
                : theme == 0
                ? Dark.background
                : Light.background,
          }}
        >
          <div className="redline">T</div>
          <div>RADE</div>
        </div>

        {masterintraday == 0 ? null : (
          <div className="containerwallet">
            <div
              onClick={() => {
                setSelect(!select);
                setWallet(true);
              }}
              className="walletbutton"
            >
              <div className="walleticon">
                <IoWallet className="icon" />{" "}
              </div>
              <div className="balance">
                <div className="balancetext">BALANCE</div>
                <div className="wallettext">
                  {parseFloat(balance).toFixed(0)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="profilecontainer">
        <div className="profilebox">
          <div className="headeruserlist">
            <div class="dropdownprofile">
              <button class="dropbtnprofile">
                {" "}
                <BiUserCircle
                  color={theme == 0 ? Dark.text : Light.text}
                />{" "}
              </button>
              <div class="dropdown-contentprofile">
                <div
                  onClick={() => {
                    setSelect(!select);
                    setProfile(true);
                  }}
                  className="link"
                >
                  PROFILE
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setLedger(true);
                  }}
                  className="link"
                >
                  LADGER
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setLog(true);
                  }}
                  className="link"
                >
                  REJECATION LOGS
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setQtySetting(true);
                  }}
                  className="link"
                >
                  QTY SETTING
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setHistory(true);
                  }}
                  className="link"
                >
                  HISTORY
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setTime(true);
                  }}
                  className="link"
                >
                  MARKET TIME
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setSetting(true);
                  }}
                  className="link"
                >
                  SETTING
                </div>
              </div>
            </div>
          </div>

          <div className="headerlogout" onClick={Logout}>
            <BiLogOut className="headerlogouticon" color={theme == 0 ? Dark.text : Light.text} />
          </div>
        </div>
      </div>

      <Dialog
        showHeader={false}
        visible={wallet}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setWallet(false)}
      >
        <Wallet closemodal={() => setWallet(false)} />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={profile}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setProfile(false)}
      >
        <Profile closemodal={() => setProfile(false)} />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={ledger}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setLedger(false)}
      >
        <Ledger closemodal={() => setLedger(false)} />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={log}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setLog(false)}
      >
        <Logs closemodal={() => setLog(false)} />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={qtysetting}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setQtySetting(false)}
      >
        <QtySetting closemodal={() => setQtySetting(false)} />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={history}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setHistory(false)}
      >
        <History closemodal={() => setHistory(false)} />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={time}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setTime(false)}
      >
        <MarketTime closemodal={() => setTime(false)} />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={setting}
        className="buysellopenmodal"
        style={{ height: windowSize[1] - 100 }}
        onHide={() => setSetting(false)}
      >
        <Setting closemodal={() => setSetting(false)} />
      </Dialog>
    </div>
  );
}
